@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    
}

html{
  scroll-behavior: smooth;
}

body{
  margin: 0px !important;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 80px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background:#222A35;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #575C66;
    border-radius: 6px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #626970;
  }

  .line{
    box-sizing: border-box;
    display: flex;
    position: relative;
    width: 100%;
    border: 3px solid #000;
    margin: 0;
  }

::selection
{
  background-color: #424141 !important;
}

.section__text {
  align-self: center;
  text-align: center;
}

.section__text p {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: rgb(66, 65, 65);
}

.section__text__p1 {
  text-align: center;
  font-family: "Poppins", sans-serif;
  @media (max-width: 560px) {
    margin-bottom: 0;
  }

}

.section__text__p2 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

.title {
  font-size: 3rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
}

#socials-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
}

/* ICONS */

.icon {
  cursor: pointer;
  height: 2rem;
}

/* CONTACT */

.contact-info-upper-container {
  display: flex;
  justify-content: center;
  border-radius: 2rem;
  border: rgb(53, 53, 53) 0.1rem solid;
  border-color: rgb(163, 163, 163);
  background: (250, 250, 250);
  margin: 2rem auto;
  padding: 0.5rem;
}

.contact-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem;
}

.contact-info-container p {
  font-size: larger;
}

.contact-icon {
  cursor: default;
}

.email-icon {
  height: 2.5rem;
}

.custom a,
.btn {
  transition: all 300ms ease;
}

.custom a {
  color: black;
  text-decoration: none;
  text-decoration-color: white;
}

.custom a:hover {
  color: rgb(104, 102, 102);
  text-decoration: underline;
  text-underline-offset: 1rem;
  text-decoration-color: rgb(134, 133, 133);
}

p a
{
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .about-containers, .contact-info-upper-container, .btn-container {
    flex-wrap: wrap;
  }
}